
import {
  mdiFacebook,
  mdiInstagram,
  mdiHomeCity,
  mdiPhoneClassic,
  mdiEmail,
} from '@mdi/js'
export default {
  data: () => ({
    info: [
      { sym: mdiPhoneClassic, ans: '1-868-758-4PTJ(785)',href:"tel:18687584785"},
      { sym: mdiEmail, ans: 'penthejourneytt@gmail.com', href:"mailto:penthejourneytt@gmail.com"},
    ],
    icons: [
      { icon: mdiFacebook, link: 'https://facebook.com/penthejourney/' },
      { icon: mdiInstagram, link: 'https://instagram.com/penthejourney/' },
    ],
    paths: [
      { text: 'About Us', link: '/#aboutus/' },
      { text: 'Store Policies', link: '/policies/' },
      /* { text: 'Terms and Conditions', link: '' }, */
    ],
  }),
}
